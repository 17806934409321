import React from 'react';
import Layout from './components/layout';
import TmhMap from './components/map';

const App = () => {
  return (
    <Layout>
      <TmhMap />
    </Layout>
  );
};

export default App;
