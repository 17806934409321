import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import MarkerWrapper from './marker-wrapper';
import { ReactComponent as MyMarker } from '../../assets/marker-icons/Icons_fleet.svg';
import CustomMarker from './marker';

const CommercialMarker = ({ position, counter, category, link }) => {
  const bg = '#56a2f8';
  const commercialMarker = L.divIcon({
    className: '',
    html: ReactDOMServer.renderToString(
      <MarkerWrapper
        background={bg}
        counter={counter}
      >
        <MyMarker />
      </MarkerWrapper>
    )
  });

  return (
    <CustomMarker
      icon={commercialMarker}
      position={position}
      category={category}
      link={link}
    />
  );
};

export default CommercialMarker;

CommercialMarker.propTypes = {
  link: PropTypes.string,
  category: PropTypes.string,
  counter: PropTypes.number,
  position: PropTypes.array.isRequired
};

