import React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import AgendaPanel from './agenda/agenda-panel';
import Markers from './markers/markers';
import Logo from '../assets/logo/logo.png';
import { zIndex, media } from '../utils';

const StyledMap = styled(Map)`
  height: 100vh;
  width: 100%;
  .leaflet-top {
    top: 25px;
    left: calc(100% - 75px);
 
    ${media.mobile`
      top: 5px;
      left: calc(100% - 50px);
    `}
  }
  .leaflet-control-attribution {
    display: none;
  }
`;

const LogoTmh = styled.img`
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: ${zIndex.level4};
  width: 220px;

  ${media.mobile`
    width: 170px;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

const TmhMap = () => {
  const position = [51.505, 7];

  return (
    <StyledMap
      preferCanvas
      center={position}
      minZoom={3}
      zoom={6}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={20}
        minZoom={0}
      />
      <LogoTmh src={Logo} />
      <Markers />
      <AgendaPanel />
    </StyledMap>
  );
};

export default TmhMap;
