export default {
  settings: {
    sectionWidth: '1100px'
  },
  colors: {
    black: '#0000',
    white: '#FFF',
    v2gMarkerBackground: '#5fcf81',
    privateMarkerBackground: '#7cd5fb',
    commercialMarkerBackground: '#56a2f8',
    essMarkerBackground: '#f5be41',
    referenceMarkerBackground: '#ed6c62',
    busesMarkerBackground: '#245aa2',
    fleetChargeMarkerBackground: '#4aa466'
  },
  boxShadows: {
    base: '0px 0px 20px 0px rgba(0,0,0,0.3)',
    darkerBase: '0px 0px 20px 0px rgba(0,0,0,0.7)'
  }
};
