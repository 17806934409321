import React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import styled from 'styled-components';
import { V2gMarker } from './index';
import countries from '../../assets/markers.json';
import EssMarker from './ess-marker';
import PrivateMarker from './private-marker';
import CommercialMarker from './commercial-marker';
import ReferenceMarker from './reference-marker';

const StyledPieChart = styled.div`
  background:
  radial-gradient(
    circle closest-side,
    transparent 85%,
    black 0
    ),
    conic-gradient(
      #f5be41 0,
      #f5be41 14%,
      #7cd5fb 0,
      #7cd5fb 28%,
      #ed6c62 0,
      #ed6c62 42%,
      #56a2f8 0,
      #56a2f8 56%,
      #245aa2 0,
      #245aa2 70%,
      #4aa466 0,
      #4aa466 84%,
      #5fcf81 0,
      #5fcf81 100%
  );
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0;
`;

const Markers = () => {
  const createClusterCustomIcon = () => {
    return L.divIcon({
      html: ReactDOMServer.renderToString(
        <StyledPieChart />
      ),
      className: ''
    });
  };

  return (
    <>
      <MarkerClusterGroup
        iconCreateFunction={createClusterCustomIcon}
        maxClusterRadius={60}
        showCoverageOnHover={false}
        removeOutsideVisibleBounds
        chunkedLoading
      >
        {countries.map(country => (
          country.locations.map((location, index) => {
            if (location.category === 'ESS project') {
              return (
                <EssMarker
                  key={index}
                  position={[location.lat, location.lng]}
                  counter={location.counter}
                  category={location.category}
                  link={location.link}
                />
              );
            }

            if (location.category === 'V2G project') {
              return (
                <V2gMarker
                  key={index}
                  position={[location.lat, location.lng]}
                  counter={location.counter}
                  category={location.category}
                  link={location.link}
                />
              );
            }

            if (location.category === 'Private') {
              return (
                <PrivateMarker
                  key={index}
                  position={[location.lat, location.lng]}
                  counter={location.counter}
                  category={location.category}
                  link={location.link}
                />
              );
            }

            if (location.category === 'Commercial') {
              return (
                <CommercialMarker
                  key={index}
                  position={[location.lat, location.lng]}
                  counter={location.counter}
                  category={location.category}
                  link={location.link}
                />
              );
            }

            if (location.category === 'References') {
              return (
                <ReferenceMarker
                  key={index}
                  position={[location.lat, location.lng]}
                  counter={location.counter}
                  category={location.category}
                  link={location.link}
                />
              );
            }
            return undefined;
          }))
        )}
      </MarkerClusterGroup>
      )
    </>
  );
};

export default Markers;
