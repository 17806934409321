import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BatteryIcon } from '../../assets/marker-icons/Icons_battery.svg';
import { ReactComponent as IconCar } from '../../assets/marker-icons/Icons_car.svg';
import { ReactComponent as IconConsulting } from '../../assets/marker-icons/Icons_consulting.svg';
import { ReactComponent as IconFleet } from '../../assets/marker-icons/Icons_fleet.svg';
import { ReactComponent as IconV2g } from '../../assets/marker-icons/Icons_v2g.svg';
import { ReactComponent as IconBus } from '../../assets/marker-icons/Icons_bus.svg';
import { ReactComponent as IconCpCustomer } from '../../assets/marker-icons/Icons_fleet-ChargePilot.svg';
import { media } from '../../utils';

const Container = styled.div`
  position: relative;
`;
const ToolTip = styled.p`
  visibility: hidden;
  opacity: 0;
  text-align: center;
  background-color: white;
  color: black;
  padding: 10px 10px;
  top: ${({ top }) => (top ? '20%' : '-5%')};
  position: absolute;
  white-space: nowrap;
  left: 135%;
  position: absolute;
`;

const StyledPieChart = styled.div`
  background:
  radial-gradient(
    circle closest-side,
    transparent 90%,
    black 0
    ),
    conic-gradient(
      #f5be41 0,
      #f5be41 14%,
      #7cd5fb 0,
      #7cd5fb 28%,
      #ed6c62 0,
      #ed6c62 42%,
      #56a2f8 0,
      #56a2f8 56%,
      #245aa2 0,
      #245aa2 70%,
      #4aa466 0,
      #4aa466 84%,
      #5fcf81 0,
      #5fcf81 100%
  );
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin-top: 30px;

  :hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: 1s all;
  }
  ${media.mobile`
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
  `}
`;

const Battery = styled(BatteryIcon)`
  background: ${({ theme }) => theme.colors.essMarkerBackground};
  height: 40px;
  border-radius: 50%;
  margin-bottom: 25px;
  > g {
    > path {
      stroke-width: 7;
      stroke: #FFFF;
    }
  }

  :hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: 1s all;
  }
  ${media.mobile`
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
  `}
`;

const Car = styled(IconCar)`
  background: ${({ theme }) => theme.colors.privateMarkerBackground};
  height: 40px;
  border-radius: 50%;
  margin-bottom: 25px;

  > g {
    > path {
      stroke-width: 7;
      stroke: #FFFF;
    }
  }

  :hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: 1s all;
  }
  ${media.mobile`
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
  `}
`;

const Consulting = styled(IconConsulting)`
  background: ${({ theme }) => theme.colors.referenceMarkerBackground};
  height: 40px;
  border-radius: 50%;
  margin-bottom: 25px;

  > g {
    > path {
      stroke-width: 7;
      stroke: #FFFF;
    }
  }

  :hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: 1s all;
  }
  ${media.mobile`
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
  `}
`;

const Fleet = styled(IconFleet)`
  background: ${({ theme }) => theme.colors.commercialMarkerBackground};
  height: 40px;
  margin-bottom: 25px;
  border-radius: 50%;

  > g {
    > path {
      stroke-width: 7;
      stroke: #FFFF;
    }
  }

  :hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: .75s all;
  }
  ${media.mobile`
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
  `}
`;

const Bus = styled(IconBus)`
  background: ${({ theme }) => theme.colors.busesMarkerBackground};
  height: 40px;
  margin-bottom: 25px;
  border-radius: 50%;

  > g {
    > path {
      stroke-width: 7;
      stroke: #FFFF;
    }
  }

  :hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: .75s all;
  }
  ${media.mobile`
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
  `}
`;

const CpCustomer = styled(IconCpCustomer)`
  background: ${({ theme }) => theme.colors.fleetChargeMarkerBackground};
  height: 40px;
  margin-bottom: 25px;
  border-radius: 50%;

  > g {
    > path {
      stroke-width: 7;
      stroke: #FFFF;
    }
  }

  :hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: .75s all;
  }
  ${media.mobile`
    height: 20px;
    margin-bottom: 15px;
    width: 20px;
  `}
`;

const V2g = styled(IconV2g)`
  background: ${({ theme }) => theme.colors.v2gMarkerBackground};
  height: 40px;
  border-radius: 50%;
  margin-bottom: 25px;

  > g {
    > path {
      stroke-width: 7;
      stroke: #FFFF;
    }
  }

  &:hover + ${ToolTip} {
    visibility: visible;
    opacity: 1;
    transition: 1s all;
  }
  ${media.mobile`
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
  `}
`;

const Icons = () => {
  return (
    <>
      <Container>
        <StyledPieChart />
        <ToolTip top >
          Group of markers
        </ToolTip>
      </Container>
      <Container>
        <Battery />
        <ToolTip >
          ESS Project
        </ToolTip>
      </Container>
      <Container>
        <Car />
        <ToolTip>
          Private
        </ToolTip>
      </Container>
      <Container>
        <Consulting />
        <ToolTip>
          Reference
        </ToolTip>
      </Container>
      <Container>
        <Fleet />
        <ToolTip>
          Commercial
        </ToolTip>
      </Container>
      <Container>
        <Bus />
        <ToolTip>
          Bus Project
        </ToolTip>
      </Container>
      <Container>
        <CpCustomer />
        <ToolTip>
          ChargePilot Customer
        </ToolTip>
      </Container>
      <Container>
        <V2g />
        <ToolTip>
          V2G Project
        </ToolTip>
      </Container>
    </>
  );
};

export default Icons;
