export default {
  level1: '1000',
  level2: '2000',
  level3: '3000',
  level4: '4000',
  level5: '5000',
  level6: '6000',
  level7: '7000',
  level8: '8000',
  level9: '9000'
};
