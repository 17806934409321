import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { zIndex } from '../../utils/index';

const Wrapper = styled.div`
  position: relative;
  background: ${({ background }) => background};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: ${zIndex.level1};
  transform: translate(-15px, -15px);
  
  >svg {
    > g {
      >path {
        stroke-width: 7;
        stroke: #FFFF;
      }
    }
  }
`;

const Counter = styled.p`
  font-size: 0.7rem;
`;

const CounterWrapper = styled.div`
  margin-bottom: -10px;
  margin-left: 25px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  border-radius: 50%;
  text-align: center;
  border: 2px solid white;
`;

const MarkerWrapper = ({ children, background, counter }) => (
  <>
    <Wrapper
      background={background}
    >
      {counter > 1 ? (
        <CounterWrapper>
          <Counter>{counter}</Counter>
        </CounterWrapper>
      ) : null}

      {children}
    </Wrapper>
  </>
);

export default MarkerWrapper;

MarkerWrapper.propTypes = {
  counter: PropTypes.number,
  children: PropTypes.node,
  background: PropTypes.string.isRequired
};
