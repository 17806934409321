import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Marker, Popup } from 'react-leaflet';

const StyledPopup = styled(Popup)`
  bottom: 10px !important;
  .leaflet-popup-content-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 0;
    text-align: center;
    box-shadow: ${({ theme }) => theme.boxShadows.darkerBase};
  };
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
`;

const StyledButton = styled.a`
  display: inline-block;
  font-size: 0.7rem;
  margin-top: 20px;

  :hover {
    cursor: pointer;
  }
`;

const CustomMarker = ({ position, icon, category, link }) => {
  return (
    <Marker
      position={position}
      icon={icon}
    >
      <StyledPopup>
        <p>
          {category}
        </p>
        <CategoryWrapper>
          {link
            ? (
              <StyledButton
                target="_blank"
                href={link}
              >
                &#10095; READ MORE
              </StyledButton>
            )
            : null}

        </CategoryWrapper>
      </StyledPopup>
    </Marker>
  );
};

export default CustomMarker;

CustomMarker.propTypes = {
  link: PropTypes.string,
  category: PropTypes.string,
  icon: PropTypes.object,
  position: PropTypes.array.isRequired
};
