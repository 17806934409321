import React from 'react';
import styled from 'styled-components';
import Icons from './icons';
import { zIndex } from '../../utils/index';

const Agenda = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  padding-left: 7px;
  padding-right: 7px;
  box-shadow: ${({ theme }) => theme.boxShadows.base};
  z-index: ${zIndex.level4};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const AgendaPanel = () => {
  return (
    <Agenda>
      <Icons />
    </Agenda>
  );
};

export default AgendaPanel;
